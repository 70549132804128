import * as React from "react";
import { Contact } from "../components";

const ContactPage = () => {
  return (
    <div className="container">
      <h1>Contact</h1>
      <Contact />
    </div>
  );
};

export default ContactPage;

export const Head = () => <title>TMOY || Contact</title>;
